// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-authors-js": () => import("./../../../src/pages/blog_authors.js" /* webpackChunkName: "component---src-pages-blog-authors-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-post-pandemic-a-js": () => import("./../../../src/pages/lp/post-pandemic-a.js" /* webpackChunkName: "component---src-pages-lp-post-pandemic-a-js" */),
  "component---src-pages-lp-post-pandemic-b-js": () => import("./../../../src/pages/lp/post-pandemic-b.js" /* webpackChunkName: "component---src-pages-lp-post-pandemic-b-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-archive-blog-archive-js": () => import("./../../../src/templates/archive/blog_archive.js" /* webpackChunkName: "component---src-templates-archive-blog-archive-js" */),
  "component---src-templates-post-post-js": () => import("./../../../src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-profile-profile-js": () => import("./../../../src/templates/profile/profile.js" /* webpackChunkName: "component---src-templates-profile-profile-js" */),
  "component---src-templates-tab-tab-js": () => import("./../../../src/templates/tab/tab.js" /* webpackChunkName: "component---src-templates-tab-tab-js" */)
}

